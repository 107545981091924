import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Layout from '../components/layout'

import Hero from '../components/Hero';
import TravelPlanning from '../components/Blocks/TravelPlanning';
import LetUsSortThat from '../components/Blocks/LetUsSortThat';
import HowItWorks from '../components/Blocks/HowItWorks';
import OurPromise from '../components/Blocks/OurPromise';
import TheBestHotels from '../components/Blocks/TheBestHotels';
import Comparison from '../components/Blocks/Comparison';
import Reviews from '../components/Blocks/Reviews';
import MediaLogos from '../components/Blocks/MediaLogos';
import Prompt from '../components/Blocks/Prompt';
import SubscriptionCalculator from '../components/Blocks/SubscriptionCalculator';
import ScrollTarget from '../components/ScrollTarget';

const processQueryData = (rawData) => {

  const data = {...rawData.data};
  data.logos = [];

  // Extract the first available image and video URLs into the hero
  // and any remaining images into the logos array
  rawData.media.forEach((media) => {
    if( /^image\//.test(media.file.contentType) ) {
      if (!data.image) {
        data.image = media.fluid.src;
      } else if (data.logos.length <= 3) {
        data.logos.push({
          src: media.file.url,
          alt: media.description,
        });
      }
    }
    if( /^video\//.test(media.file.contentType) && !data.video) {
      data.video = media.file.url;
    }
  });

  return data;
};
class IndexPage extends React.PureComponent {
  render() {

    const heroData = processQueryData(this.props.data.contentfulConfigurationItem);

    return (
      <Layout startNavTransparent={true}>

        <Helmet>
          <title>
            BeRightBack - Discover Europe One Surprise Destination at a Time
          </title>
          <meta
            name="description"
            content="BRB is the world's first travel subscription service. Pay monthly and get a break in Europe every 4 months to a surprise destination."
          />
          <meta
            name="keywords"
            content="travel, be right back, brb, BRB, surprise, europe, holidays, subscription, flights, accommodation, hotel, app, travel agent"
          />
          <meta name="robots" content="index, follow" />
          <meta property="og:type" content="product" />
          <meta
            property="og:title"
            content="BRB: The world's first travel subscription"
          />
          <meta property="og:url" content="https://berightback.travel" />
          <meta property="og:image" content={heroData.image} />
        </Helmet>

        <Hero
          image={heroData.image}
          title={heroData.title}
          text={heroData.text}
          video={heroData.video}
          cta={heroData.cta}
          logos={heroData.logos}
        />

        <TravelPlanning />
        <LetUsSortThat />
        <Prompt dataName="How It Works - intro"/>
        <ScrollTarget id="how-it-works" />
        <HowItWorks />
        <OurPromise />
        <TheBestHotels />
        <Comparison />
        <ScrollTarget id="reviews" />
        <Reviews />
        <MediaLogos />
        <SubscriptionCalculator />

      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query hero {
    contentfulConfigurationItem(name: {eq: "Homepage - hero"}) {
      name
      data {
        title
        text
        cta {
          label
          link
          type
        }
      }
      media {
        description
        fluid(maxWidth: 1200, quality: 75) {
          src
        }
        file {
          contentType
          url
        }
      }
    }
  }
`
